<ng-template #pageHeader>
  <ix-page-title-header>
    <ix-entity-table-add-actions [entity]="asGenericTable(this)"></ix-entity-table-add-actions>
  </ix-page-title-header>
</ng-template>

<div
  fxLayout="column"
  id="entity-table-component"
  class="material mat-card mat-card-table"
  [class.is-empty]="isTableEmpty"
>
  <ng-container>
    <!-- BATCH OPERATIONS START -->
    <div
      *ngIf="conf && selection.selected.length"
      fxLayout="row wrap"
      fxLayoutAlign="start center"
      class="multiActionsButton fn-toolbar"
      [style.display]="selection.selected.length ? 'block' : 'none'"
    >
      <div fxFlex="100%">
        <div *ngIf="conf.multiActions.length" class="multiactions-title">
          <strong>{{ 'Batch Operations' | translate }}</strong>
        </div>

        <div
          *ngIf="conf.multiActions && conf.multiActions.length"
          fxLayout="row wrap"
          fxLayoutGap="16px"
        >
          <span
            *ngFor="let maction of conf.multiActions"
            ix-auto
            ix-auto-type="button"
            ix-auto-identifier="{{ maction?.id }}"
          >
            <button
              *ngIf="maction.enable"
              mat-button
              id="{{ maction?.id }}"
              (click)="maction.onClick(this.selection.selected)"
            >
              <mat-icon>{{ maction?.icon }}</mat-icon>
              &nbsp;
              <span>{{ maction?.label | translate }}</span>
            </button>
          </span>
          &nbsp;
        </div>

        <div *ngIf="!conf.multiActions || conf.multiActions.length === 0">
          <span ix-auto ix-auto-type="button" ix-auto-identifier="mdelete">
            <!-- With Labels -->
            <ng-container>
              <button
                mat-button
                (click)="doMultiDelete(this.selection.selected)"
              >
                <mat-icon>delete</mat-icon>
                <br />
                <span>
                  {{ 'Delete' | translate }}
                </span>
              </button>
            </ng-container>

            <!-- Without Labels -->
            <ng-container>
              <button
                mat-button
                matTooltipPosition="below"
                matTooltip="{{ 'Delete selections' | translate }}"
                (click)="doMultiDelete(this.selection.selected)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </ng-container>
          </span>
        </div>
      </div>
    </div>
    <!-- BATCH OPERATIONS END -->

    <div class="table-container">
      <table
        *ngIf="
          conf && currentColumns && currentColumns.length > 0 && dataSource
        "
        #newEntityTable
        multiTemplateDataRows
        mat-table
        matSort
        [dataSource]="dataSource"
        [style.minWidth.px]="displayedColumns.length * 25"
      >
        <ng-container *ngFor="let column of columnsProps; let i = index">
          <!-- Column Template -->
          <ng-container
            *ngIf="isBasicColumnTemplate(column)"
            matColumnDef="{{ column }}"
            [sticky]="isLeftStickyColumnNo(i)"
          >
            <th
              *matHeaderCellDef
              class="data-column"
              mat-header-cell
              [class.sticky-left-offset]="shouldApplyStickyOffset(i)"
              [class.sticky-border-right]="
                isLeftStickyColumnNo(i) && isTableOverflow()
              "
              [class.padded-away]="
                !shouldApplyStickyOffset(i) &&
                !(isLeftStickyColumnNo(i) && isTableOverflow())
              "
            >
              {{ currentColumns[i].name | translate }}
            </th>
            <td
              *matCellDef="let element"
              class="data-column"
              mat-cell
              [class.sticky-left-offset]="shouldApplyStickyOffset(i)"
              [class.sticky-border-right]="
                isLeftStickyColumnNo(i) && isTableOverflow()
              "
              [ngStyle]="{
                cursor: checkLength() && !conf.onRowClick ? 'auto' : 'pointer'
              }"
              [class.padded-away]="
                !shouldApplyStickyOffset(i) &&
                !(isLeftStickyColumnNo(i) && isTableOverflow())
              "
              (mouseover)="onHover($event, true)"
              (mouseout)="onHover($event, false)"
            >
              <div
                class="text-overflow-ellipsis"
                [matTooltip]="
                  currentColumns[i].enableMatTooltip ? element[column] : null
                "
              >
                {{ element[column] }}
              </div>
            </td>
          </ng-container>

          <!-- Expansion Chevrons -->
          <ng-container
            *ngIf="column === 'expansion-chevrons'"
            stickyEnd
            matColumnDef="{{ column }}"
          >
            <th
              *matHeaderCellDef
              class="expansion-chevrons-column"
              mat-header-cell
              [ngClass]="{ 'sticky-border-left': isTableOverflow() }"
            ></th>
            <td
              *matCellDef="let element"
              class="expansion-chevrons-column"
              mat-cell
              [ngClass]="{ 'sticky-border-left': isTableOverflow() }"
              (mouseover)="onHover($event, true)"
              (mouseout)="onHover($event, false)"
            >
              <ng-container *ngIf="hasDetails() === true">
                <ng-container *ngIf="element === expandedElement">
                  <button
                    class="transparent-btn"
                    [attr.aria-label]="'Close expanded row' | translate"
                  >
                    <mat-icon
                      fontSet="mdi-set"
                      fontIcon="mdi-chevron-up"
                      (mouseover)="onHover($event, true)"
                      (mouseout)="onHover($event, false)"
                    ></mat-icon>
                  </button>
                </ng-container>
                <ng-container *ngIf="element !== expandedElement">
                  <button
                    class="transparent-btn"
                    [attr.aria-label]="'Expand row' | translate"
                  >
                    <mat-icon
                      fontSet="mdi-set"
                      fontIcon="mdi-chevron-down"
                      (mouseover)="onHover($event, true)"
                      (mouseout)="onHover($event, false)"
                    ></mat-icon>
                  </button>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <!-- Threedot Actions -->
          <ng-container
            *ngIf="column === 'action'"
            stickyEnd
            matColumnDef="{{ column }}"
          >
            <th
              *matHeaderCellDef
              class="threedot-column"
              mat-header-cell
              [ngClass]="{ 'sticky-border-left': isTableOverflow() }"
            ></th>
            <td
              *matCellDef="let element"
              class="threedot-column"
              mat-cell
              [ngClass]="{ 'sticky-border-left': isTableOverflow() }"
              (mouseover)="onHover($event, true)"
              (mouseout)="onHover($event, false)"
            >
              <ix-entity-table-actions
                [entity]="asGenericTable(this)"
                [row]="element"
                (mouseover)="onHover($event, true)"
                (mouseout)="onHover($event, false)"
              >
              </ix-entity-table-actions>
            </td>
          </ng-container>

          <!-- Multiselect Checkboxes -->
          <ng-container
            *ngIf="column === 'multiselect'"
            sticky
            matColumnDef="{{ column }}"
          >
            <th *matHeaderCellDef class="multiselect-column" mat-header-cell>
              <mat-checkbox
                color="primary"
                ix-auto
                ix-auto-type="checkbox"
                ix-auto-identifier="title"
                [indeterminate]="selection.hasValue() && !isAllSelected"
                [checked]="selection.hasValue() && isAllSelected"
                (change)="masterToggle($event)"
              ></mat-checkbox>
            </th>
            <td
              *matCellDef="let element"
              class="multiselect-column"
              mat-cell
              [ngStyle]="{ cursor: checkLength() ? 'auto' : 'pointer' }"
              (mouseover)="onHover($event, true)"
              (mouseout)="onHover($event, false)"
            >
              <mat-checkbox
                color="primary"
                ix-auto
                ix-auto-type="checkbox"
                [checked]="selection.isSelected(element)"
                [ix-auto-identifier]="element[conf.rowIdentifier || 'name']"
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleSelection(element) : null"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Interactive Toggle Column -->
          <ng-container
            *ngIf="column === 'state' && isInteractive(column)"
            matColumnDef="{{ column }}"
          >
            <th
              *matHeaderCellDef
              class="toggle-column"
              mat-header-cell
              [class.padded-away]="
                !shouldApplyStickyOffset(i) &&
                !(isLeftStickyColumnNo(i) && isTableOverflow())
              "
            >
              {{ currentColumns[i].name | translate }}
            </th>
            <td
              *matCellDef="let element"
              class="toggle-column"
              mat-cell
              [class.padded-away]="
                !shouldApplyStickyOffset(i) &&
                !(isLeftStickyColumnNo(i) && isTableOverflow())
              "
              (mouseover)="onHover($event, true)"
              (mouseout)="onHover($event, false)"
            >
              <mat-spinner
                *ngIf="element['onChanging']; else actionButtons"
                [diameter]="40"
              ></mat-spinner>
              <ng-template #actionButtons>
                <!-- Toggle -->
                <div
                  *ngIf="currentColumns[i].toggle"
                  class="clickable"
                  ix-auto
                  ix-auto-type="overlay"
                  matTooltipPosition="right"
                  id="overlay__{{ getRowIdentifier(element) }}_Running"
                  matTooltip="{{ element.state }}"
                  [ix-auto-identifier]="column + getRowIdentifier(element)"
                  (click)="
                    $event.stopPropagation(); this.conf.onSliderChange(element)
                  "
                ></div>

                <mat-slide-toggle
                  *ngIf="currentColumns[i].toggle"
                  ix-auto
                  ix-auto-type="slider"
                  color="primary"
                  id="slide-toggle__{{ column }}_{{ getRowIdentifier(element) }}"
                  ix-auto-identifier="{{ column }}__{{ getRowIdentifier(element) }}"
                  [checked]="element[column] === EntityJobState.Running"
                >
                </mat-slide-toggle>

                <!-- Button -->
                <button
                  *ngIf="currentColumns[i].button"
                  mat-stroked-button
                  ix-auto
                  ix-auto-type="slider"
                  id="interactive-button_{{ column }}_{{ getRowIdentifier(element) }}"
                  ix-auto-identifier="{{ column }}__{{ getRowIdentifier(element) }}"
                  [ngClass]="getButtonClass(element[column])"
                  (click)="
                    $event.stopPropagation(); this.conf.onButtonClick(element)
                  "
                >
                  {{ element[column].state }}
                  <div
                    *ngIf="element[column].warnings?.length > 0"
                    class="label-warning-icon"
                  >
                    <mat-icon fontSet="mdi-set" fontIcon="mdi-alert"></mat-icon>
                  </div>
                </button>
              </ng-template>
            </td>
          </ng-container>

          <!-- Interactive Checkbox Column -->
          <ng-container
            *ngIf="
              ['enabled', 'enable', 'autostart'].includes(column) &&
              isInteractive(column)
            "
            matColumnDef="{{ column }}"
          >
            <th
              *matHeaderCellDef
              class="toggle-column"
              mat-header-cell
              [class.padded-away]="isPaddedAway(i)"
            >
              {{ currentColumns[i].name | translate }}
            </th>
            <td
              *matCellDef="let element"
              class="toggle-column"
              mat-cell
              [class.padded-away]="isPaddedAway(i)"
              (mouseover)="onHover($event, true)"
              (mouseout)="onHover($event, false)"
            >
              <mat-checkbox
                *ngIf="!(checkboxLoaders.get(getRowIdentifier(element)) | async)"
                color="primary"
                class="checkbox"
                ix-auto
                ix-auto-type="checkbox"
                id="checkbox__{{ getRowIdentifier(element) }}"
                ix-auto-identifier="{{ column }}__{{ getRowIdentifier(element) }}"
                [disabled]="getDisabled(column)"
                [(ngModel)]="element[column]"
                (change)="checkboxChanged(element)"
                (click)="$event.stopPropagation()"
              ></mat-checkbox>

              <mat-spinner
                mode="indeterminate"
                [diameter]="25"
                *ngIf="checkboxLoaders.get(getRowIdentifier(element)) | async"
                id="checkbox_spinner__{{ getRowIdentifier(element) }}"
              ></mat-spinner>
            </td>
          </ng-container>

          <ng-container
            *ngIf="['path', 'paths', 'path_local'].includes(column) && isInteractive(column)"
            matColumnDef="{{ column }}"
          >
            <th *matHeaderCellDef mat-header-cell>
              {{ currentColumns[i].name | translate }}
            </th>
            <td
              *matCellDef="let element"
              mat-cell
              (mouseover)="onHover($event, true)"
              (mouseout)="onHover($event, false)"
            >
              <div class="path-locked-status">
                <div class="text-overflow-ellipsis">{{ element[column] }}</div>
                <mat-icon
                  *ngIf="element.locked"
                  class="append"
                  [matTooltip]="'Dataset is currently locked' | translate"
                >
                  locked
                </mat-icon>
              </div>
            </td>
          </ng-container>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td
            *matCellDef="let element"
            mat-cell
            [attr.colspan]="currentColumns.length"
          >
            <div
              [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
            >
              <ix-entity-table-row-details
                [config]="element"
                [parent]="asGenericTable(this)"
              ></ix-entity-table-row-details>
            </div>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="columnsProps" mat-header-row></tr>
        <tr
          *matRowDef="let element; columns: columnsProps; let i = dataIndex"
          mat-row
          class="element-row"
          ix-auto
          ix-auto-type="expander"
          id="{{ element[getFirstKey()] }}"
          ix-auto-identifier="{{ element[getFirstKey()] }}"
          [ngClass]="{ 'expanded-row': expandedElement === element }"
          (click)="doRowClick(element)"
        ></tr>

        <ng-container *ngIf="hasDetails() === true">
          <tr
            *matRowDef="let row; columns: ['expandedDetail']"
            mat-row
            class="details-row"
          ></tr>
        </ng-container>
      </table>
    </div>

    <mat-card-footer *ngIf="conf.config.paging">
      <mat-paginator
        [pageIndex]="paginationPageIndex"
        [pageSize]="paginationPageSize"
        [pageSizeOptions]="paginationPageSizeOptions"
        [showFirstLastButtons]="paginationShowFirstLastButtons"
        (page)="pageChanged()"
      ></mat-paginator>
    </mat-card-footer>
  </ng-container>
  <ng-container *ngIf="isTableEmpty">
    <div
      class="entity-empty-wrapper"
      fxFlex="100%"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="32px"
    >
      <ix-entity-empty [conf]="emptyTableConf"></ix-entity-empty>
    </div>
  </ng-container>
</div>
