<div
  *ngIf="!config['isHidden']"
  fxLayout="column"
  class="dynamic-field form-list"
  ix-auto
  ix-auto-type="list"
  ix-auto-identifier="{{ config.placeholder }}"
  [formGroup]="group"
  [ngClass]="{ box: config.box, 'sub-box': !config.box }"
  [attr.id]="config.name"
>
  <div
    fxLayout="row"
    fxFlex="100%"
    fxLayoutAlign="space-between center"
    class="list-top-bar"
  >
    <label class="list-label">
      {{ config.label ? config.label : 'Add ' + config.name }}
    </label>
    <button
      *ngIf="!config.hideButton"
      mat-button
      class="addBtn"
      type="button"
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="add-box_{{ config.name }}"
      (click)="add()"
    >
      {{ 'Add' | translate }}
    </button>
  </div>
  <ng-container *ngIf="listsFromArray?.controls.length > 0; else empty">
    <div
      *ngFor="let liControl of listsFromArray.controls; let i = index"
      ix-auto
      ix-auto-type="list-item"
      class="form-list-item"
      ix-auto-identifier="{{ i }}"
      [fxLayout]="config.width === '100%' ? 'row wrap' : ''"
    >
      <ng-container *ngFor="let field of config.listFields[i]; let ii = index">
        <div
          *ngIf="!field.isHidden"
          fxFlex="calc(100% - 100px)"
          fxFlex.gt-xs="calc({{ field.width }} - 100px - 32px)"
          [ngClass]="{
            'form-inline': field.class === 'inline',
            'form-line': field.class !== 'inline'
          }"
        >
          <div
            dynamicField
            [config]="field"
            [group]="listsFromArray.controls[i] | cast"
          ></div>
        </div>
      </ng-container>
      <div
        *ngIf="!config.hideButton"
        class="list-action list-action-inline"
        [style.padding-bottom]="config.width === '100%' ? '10px' : '0'"
      >
        <div>
          <button
            mat-icon-button
            class="top-right"
            ix-auto
            ix-auto-type="icon-button"
            ix-auto-identifier="delete-box_{{ config.name + i }}"
            (click)="delete(i)"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #empty>
    <span class="form-list-item">
      {{ 'No items have been added yet.' | translate }}
    </span>
  </ng-template>

  <ix-form-errors
    [control]="group.controls[config.name]"
    [config]="config"
  ></ix-form-errors>

  <mat-error *ngIf="config['hasErrors']">
    <div [innerHTML]="config['errors']"></div>
  </mat-error>
</div>
