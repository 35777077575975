<mat-sidenav-container
  class="app-side-nav-container"
  fxLayout="row"
  ngClass.xs="xs"
  ngClass.sm="sm"
  ngClass.md="md"
  ngClass.lg="lg"
  ngClass.xl="xl"
>
  <!-- Main side navigation -->
  <mat-sidenav
    #sidenav
    class="sidebar-panel not-alerts"
    [fxFlex]="sidenavWidth"
    [opened]="isSidenavOpen"
    [mode]="sidenavMode"
    (closedStart)="onMenuClosed()"
  >
    <div id="scroll-area" class="navigation-hold" fxLayout="column">
      <div
        ix-auto
        ix-auto-type="button"
        ix-auto-identifier="logo"
        class="branding topbar {{'truenas-' + (productType$ | async | lowercase)}}"
      >
        <a routerLink="/dashboard" class="logo">
          <mat-icon
            alt="TrueNAS SCALE"
            class="app-logo"
            [svgIcon]="themeService.isDefaultTheme ? 'ix:truenas_scale_logomark_color': 'ix:truenas_scale_logomark'"
          ></mat-icon>
        </a>
        <a routerLink="/dashboard" class="logo-text">
          <mat-icon
            alt="TrueNAS SCALE"
            class="app-logo-text"
            [svgIcon]="themeService.isDefaultTheme? 'ix:truenas_scale_logotype_color': 'ix:truenas_scale_logotype'"
          ></mat-icon>
        </a>
      </div>

      <ix-navigation
        (menuToggled)="toggleMenu($event)"
        (menuClosed)="toggleMenu()"
      ></ix-navigation>

      <div class="slidein-nav slidein-nav-sm" [class.slidein-open]="isOpen">
        <ix-secondary-menu
          [subMenuItems]="subs"
          [menuName]="menuName"
          (toggleMenu)="toggleMenu()"
        ></ix-secondary-menu>
      </div>

      <div *ngIf="!isSidenavCollapsed" class="sidenav-copyright-txt">
        <div
          *ngIf="hostname"
          class="hostname-label"
          matTooltip="Hostname: {{ hostname }}"
        >
          {{ hostname }}
        </div>
        <ix-copyright-line class="copyright-line"></ix-copyright-line>
      </div>
      <div
        *ngIf="isSidenavCollapsed"
        class="sidenav-copyright-icon"
        matTooltipPosition="right"
        matTooltip="TrueNAS {{productType$ | async | mapValue: productTypeLabels}}® © {{ copyrightYear$ | async }} by iXsystems Inc."
      >
        <mat-icon>copyright</mat-icon>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content
    class="fn-maincontent"
    fxFlex="calc(100% - {{ sidenavWidth }})"
    [style.margin-left]="sidenavWidth"
  >
    <!-- Top Bar -->
    <ix-topbar [sidenav]="sidenav"></ix-topbar>

    <div class="slidein-nav slidein-nav-md" [class.slidein-open]="isOpen">
      <ix-secondary-menu
        [subMenuItems]="subs"
        [menuName]="menuName"
        (toggleMenu)="toggleMenu()"
      ></ix-secondary-menu>
    </div>
    <div class="overlay" [class.isdark]="isOpen" (click)="toggleMenu()"></div>

    <!-- App content -->
    <div
      class="rightside-content-hold"
      [class.has-footer]="hasConsoleFooter$ | async"
    >
      <ix-page-title-header *ngIf="!headerPortalOutlet"></ix-page-title-header>
      <ng-template
        *ngIf="headerPortalOutlet"
        [cdkPortalOutlet]="headerPortalOutlet"
      ></ng-template>
      <router-outlet></router-outlet>
    </div>
    <ix-console-footer *ngIf="hasConsoleFooter$ | async"></ix-console-footer>
  </mat-sidenav-content>
  <!-- Alert bar -->
  <mat-sidenav
    class="alert-sidenav"
    position="end"
    [opened]="isAlertPanelOpen$ | async"
    (closed)="onAlertsPanelClosed()"
  >
    <ix-alerts-panel></ix-alerts-panel>
  </mat-sidenav>
</mat-sidenav-container>

<ix-jw-modal id="slide-in-form"></ix-jw-modal>
<ix-slide-in id="ix-slide-in-form"></ix-slide-in>
