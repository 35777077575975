<div *ngIf="control.dirty || control.touched" class="form-error">
  <mat-error *ngIf="control.hasError('required') && !config.hideErrMsg">
    {{ '{field} is required.' | translate: { field: config.placeholder } }}
  </mat-error>

  <mat-error *ngIf="control.hasError('minlength')">
    {{
      'The length of {field} should be at least {minLength}.' | translate
        : {
            field: config.placeholder,
            minLength: control.errors.minlength.requiredLength
          }
    }}
  </mat-error>

  <mat-error *ngIf="control.hasError('maxlength')">
    {{
      'The length of {field} should be no more than {maxLength}.' | translate
        : {
            field: config.placeholder,
            maxLength: control.errors.maxlength.requiredLength
          }
    }}
  </mat-error>

  <mat-error *ngIf="control.hasError('min')">
    {{ 'Minimum value is {value}.' | translate: { value: control.errors.min.min } }}
  </mat-error>

  <mat-error *ngIf="control.hasError('atLeastOne')">
    {{
      '{this} and {that}. At least one is required' | translate
        : { this: control.errors.fields[0], that: control.errors.fields[1] }
    }}
  </mat-error>

  <mat-error *ngIf="control.hasError('greaterThan')">
    {{ 'Value must be greater than {value}.' | translate: { value: control.errors.fields[0] } }}
  </mat-error>

  <mat-error *ngIf="control.hasError('forbidden')">
    {{ 'The name "{value}" is already in use.' | translate: { value: control.errors.value } }}
  </mat-error>

  <mat-error *ngIf="control.hasError('range')">
    {{
      'The value is out of range. Enter a value between {min} and {max}.' | translate
        : {
            min: control.errors.rangeValue.min,
            max: control.errors.rangeValue.max
          }
    }}
  </mat-error>

  <mat-error *ngIf="control.hasError('regex') || (control.hasError('pattern') && !config.hideErrMsg)">
    {{ 'Invalid format or character.' | translate }}
  </mat-error>

  <mat-error *ngIf="control.hasError('ip')">
    {{
      'Select {this} to include all {these} addresses. When this has been chosen, additional addresses cannot be selected.' | translate
        : { this: control.errors.info[1], these: control.errors.info[0] }
    }}
  </mat-error>

  <mat-error *ngIf="control.hasError('ip2')">
    {{ 'Invalid IP address' | translate }}
  </mat-error>

  <mat-error *ngIf="control.hasError('matchOther')">
    {{ 'The passwords do not match.' | translate }}
  </mat-error>

  <mat-error
    *ngIf="control.hasError('manualValidateError')"
    [innerHtml]="control.errors.manualValidateErrorMsg | translate"
  ></mat-error>

  <mat-error *ngIf="control.hasError('invalidOptionSelected')">
    {{ 'Invalid option selected' | translate }}
  </mat-error>

  <mat-error *ngIf="control.hasError('email')">
    {{ 'Value must be a valid email address' | translate }}
  </mat-error>
</div>
